import React from 'react'
import {EditGuesser} from '@api-platform/admin'
import {TextInput } from 'react-admin'

const Edit = ({...props}) => (
    <EditGuesser {...props}>
        <TextInput source={'values'} label={"Valeurs"} multiline={true} fullWidth={true} />
    </EditGuesser>
)

export default Edit
