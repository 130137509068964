import React from 'react'
import { EditGuesser, InputGuesser } from '@api-platform/admin'
import { BooleanInput, TextInput } from 'react-admin'
import ReferenceInput from "../../components/ReferenceInput";

const Edit = ({ ...props }) => (
  <EditGuesser {...props}>
    <InputGuesser source={'name'} />
    <InputGuesser source={'vatIdentificationNumber'} />
    <InputGuesser source={'streetAddress'} />
    <InputGuesser source={'additionalAddress'} />
    <InputGuesser source={'postalCode'} />
    <InputGuesser source={'city'} />
    <InputGuesser source={'country'} />
    <InputGuesser source={'legalRepresentativeFirstName'} />
    <InputGuesser source={'legalRepresentativeLastName'} />
    <InputGuesser source={'legalRepresentativeBirthDay'} />
    <InputGuesser source={'legalRepresentativeNationality'} />
    <InputGuesser source={'legalRepresentativeCountryOfResidence'} />
    <InputGuesser source={'legalRepresentativeEmail'} />
    <InputGuesser source={'legalRepresentativeStreetAddress'} />
    <InputGuesser source={'legalRepresentativeAdditionalStreetAddress'} />
    <InputGuesser source={'legalRepresentativePostalCode'} />
    <InputGuesser source={'legalRepresentativeCity'} />
    <InputGuesser source={'legalRepresentativeCountry'} />
    <InputGuesser source={'headquartersStreetAddress'} />
    <InputGuesser source={'headquartersAdditionalStreetAddress'} />
    <InputGuesser source={'headquartersPostalCode'} />
    <InputGuesser source={'headquartersCity'} />
    <InputGuesser source={'headquartersCountry'} />
    <BooleanInput source={'isNew'} />
    <TextInput source={'accountManagerInitials'} />
    <BooleanInput source={'allowedToUseECmr'} />
    <ReferenceInput source={'priceSpecificationT1'} reference='price_specifications' />
    <ReferenceInput source={'priceSpecificationT2'} reference='price_specifications' />
    <ReferenceInput source={'priceSpecificationT3'} reference='price_specifications' />
    <TextInput source={'departments'} multiline={true} fullWidth={true} />
  </EditGuesser>
)

export default Edit
