import React from 'react'
import { ResourceGuesser } from '@api-platform/admin'
import List from './List'
import Edit from './Edit'
import MapIcon from '@material-ui/icons/Map'

const Zone = ({ ...props }) => (
  <ResourceGuesser
    {...props}
    name='zones'
    icon={MapIcon}
    create={null}
    list={List}
    edit={Edit}
  />
)

export default Zone
